import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextField from "@atoms/TextFieldAssessment";
import TextArea from "@atoms/TextAreaAssessment";
import ButtonType from "@atoms/ButtonTypeAssessment";
import { createSendContactMessageForEducation } from "@services/sunshine.service";
import Image from "next/image";
import Link from "next/link";
import { yupValidator } from "@lib/yup-validator";
import Text from "@atoms/TextAssessment";

function FeedbackForm({ setShowSuccessDialog }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        firstName: yup
          .string()
          .required("First Name is mandatory")
          .matches(/^[a-zA-Z ]*$/, "Please enter valid first name"),
        lastName: yup
          .string()
          .required("Last Name is mandatory")
          .matches(/^[a-zA-Z ]*$/, "Please enter valid last name"),
        phone: yup
          .string()
          .required("phone is mandatory")
          .matches(/^[0-9]{10}$/, "Invalid phone number"),
        message: yup.string().required("Message is mandatory"),
      })
    ),
  });

  const onSubmit = (data) => {
    createSendContactMessageForEducation(data).then((res) => {
      if (res.status) {
        setShowSuccessDialog(true);
        setValue("firstName", "");
        setValue("lastName", "");
        setValue("phone", "");
        setValue("message", "");
      }
    });
  };

  return (
    <div
      id="feedbackSection"
      className={` flex flex-col md:flex-row  gap-16`}
    >
      <div className=" bg-white mx-auto pt-0 md:pt-14 mb-0 lg:mb-6  max-w-[515px]">
        <h1 className="text-xl md:text-[30px]  leading-6 md:leading-8  mb-6">
          Become Unstoppable: Are you ready to crush every barrier
          that's limiting your success?
        </h1>

        <Text type="bodyStyleLarge" className="text-sm font-normal   leading-5">
          Connect with us to explore LISSUN’s tailored solutions and unlock your hidden potential
        </Text>

        <div className="">
          <Image
            src="/images/education/FormGrowing.svg"
            width={400}
            height={400}
            className=""
            objectFit="contain"
          />
        </div>
      </div>
      <div className=" relative z-50">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="relative bg-secondary-900 rounded p-4 md:px-8 md:pt-6 md:pb-8 md:mb-0 mb-4 shadow-xl h-fit after:bg-[url('/images/education/contact-bg.jpg')] after:absolute after:-bottom-4 md:after:-bottom-9 after:-right-4 md:after:-right-9 after:w-full after:h-full after:-z-10"
        >
          <div className=" ">
            <div className="mb-4  ">
              <TextField
                label="First Name"
                id="firstName"
                register={register}
                error={errors.firstName}
              ></TextField>
            </div>
            <div className="mb-4 ">
              <TextField
                label="Last Name"
                id="lastName"
                register={register}
                error={errors.lastName}
              ></TextField>
            </div>
          </div>
          <div className="mb-4">
            <TextField
              label="Phone Number"
              id="phone"
              register={register}
              error={errors.phone}
            ></TextField>
          </div>
          <div className="mb-6">
            <TextArea
              className={"h-36"}
              label="Message"
              id="message"
              register={register}
              error={errors.message}
              labelClassName={"a"}
            ></TextArea>
          </div>
          {/* <div>
            <input className="mr-2" type="checkbox" />
            Notify me when LISSUN launches their student focused product
          </div> */}


          <div className="flex w-full mt-4 lg:mt-8 -mb-5">
            <ButtonType
              value="Submit"
              className="w-full bg-primary-100 py-2.5  text-white !rounded"
              type={"rectangleButtonColorCustom"}
              onClick={handleSubmit(onSubmit)}
            >
              {"Submit"}
            </ButtonType>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FeedbackForm;
