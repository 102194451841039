import React from "react";
import Image from "next/image";
import Text from "@atoms/TextAssessment";

function Gallery({ gallery, onClick, isImageWithText = false }) {
  return (
    <div className="grid md:grid-cols-4 grid-cols-2 gap-2 mx-1 lg:gap-8 xl:gap-12 mb-4 mt-8 md:mb-6">
      {gallery.map((img, index) => (
        <>
          {
            isImageWithText ? (
              <div className={`relative `} key={img.id}>
                <div className={`relative bg-gray-100 mb-1.5 w-full  h-48 md:h-72 rounded-xl`}>
                  <Image
                    src={img.thumbnailUrl}
                    objectFit='cover'
                    className={"rounded-xl"}
                    layout='fill'
                    id={img.id}
                    onClick={() => onClick(img.slug)}

                  />
                </div>
                <Text className={`text-lg font-normal ml-1`}>{img.displayName}</Text>
                <Text className={`text-base font-normal ml-1 text-[#838383]`}>{img.contentCount}</Text>
              </div>
            )
              : (
                <div className="" key={img.id}>
                  <div
                    key={img.id}
                    className="text-white flex flex-col gap-2 rounded-3xl overflow-hidden cursor-pointer"
                    id={img.id}
                    onClick={() => onClick(img.slug)}
                  >
                    <div className="relative cursor-pointe h-44 xl:h-80">
                      <Image
                        src={img.thumbnailUrl}
                        layout="fill"
                        objectFit="cover"
                        objectPosition="top"
                      />

                      <div className="absolute top-0 bg-black bg-opacity-60 w-full h-full flex items-center justify-center p-4 transition ease-in-out duration-700">
                        <Text
                          color="text-white"
                          className="font-semibold font-Nunito text-xs lg:text-2xl leading-6 truncate"
                        >
                          {img.displayName}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              )
          }
        </>
      ))}
    </div>
  );
}

export default Gallery;
