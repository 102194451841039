import React from "react";
import Heading from "@atoms/HeadingAssessment";
import Text from "@atoms/TextAssessment";
import Button from "@atoms/ButtonAssessment";
import Image from "next/image";
const PaymentSuccess = ({ close }) => {

    return (
        <div>
            <div className=" fixed flex items-center justify-center w-full h-full top-0 left-0 bg-black bg-opacity-40 z-[100] ">
                <div className=" relative w-11/12 md:max-w-[580px] p-6 bg-white border-gray-400 border  rounded-lg">
                    <div className=" flex flex-col items-center pt-10">
                        <Image
                            width={"64"}
                            height={"64"}
                            src="/images/successEmoji.svg"
                            className="cursor-pointer  mt-1 mr-3"
                        />
                        <Text
                            type="bodyCaption5"
                            className="w-full font-Nunito  text-center mt-4 mb-7"
                        >
                            Thanks for reaching out to us, our team will get back to you soon
                        </Text>

                        <Button
                            type="largeContained"
                            className=" py-2 mb-4 px-10 min-w-[134px] font-semibold "
                            onClick={close}
                        >
                            Okay
                        </Button>

                        <Text type='bodyCaption4' className="absolute right-6 top-3  text-xl text-neutral-60 cursor-pointer h-6 w-6" onClick={close} >x</Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess;
