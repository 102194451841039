import React from "react";
import Image from "next/image";
import Text from "@atoms/TextAssessment";

const EduServiceCard = ({ icon, iconWidth = 60, iconHeight = 60, title }) => {
    const myLoader = ({ src }) => src;
    return (
        <div className="bg-white rounded-xl max-w-[295px] lg:h-[237px] px-3 py-6  lg:px-5 lg:py-11 border border-[#E0E0E0] shadow-[0_10.773px_21.545px_0_rgba(0,0,0,0.15)]">
            <div className="flex justify-center">
                <Image
                    loader={myLoader}
                    src={icon}
                    width={iconWidth}
                    height={iconHeight}
                />
            </div>

            <Text
                className=" mt-[22px] w-full text-center text-xs leading-4 lg:text-xl "
            >
                {title}
            </Text>
        </div>
    );
};

export default EduServiceCard;
